import { C_Logo } from '@/src/components/c_Logo';
import classes from './style.module.scss';
import classNames from "classnames";
import { C_MainButton } from '@/src/components/c_MainButton';
import {useContext} from "react";
import {Context} from "@/src/library";

export const S_Investment = ({className} : {className?: any}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });
    const {menu, popup} = useContext(Context);
    const [popupState, setPopupState] = popup;
    return (
      <div className={classes.wrapRoot}>
        <div className={cls}>
            <div className={classes.block_wrapper}>
                <h2>Рост цены м&#178; за период строительства{`\u00A0`}до{`\u00A0`}40%</h2>
                <div className={classes.wrapper_description}>Доход от сдачи офиса в аренду почти в 1,5 раза выше, чем в сегменте жилой недвижимости</div>
                <C_MainButton className={classes.btn_more} text="Узнать больше" onClick={() => setPopupState((o: any) => !o)} />
                <div className={classes.vert_text}>Высокий доход</div>
                <div className={classes.procent}><span>0%</span> Рассрочка</div>
            </div>
            <div className={classes.text}>Получение долгосрочной прибыли при сдаче объекта в аренду</div>
        </div>
      </div>
  )
}
