import InputMask from 'react-input-mask';
import classes from './style.module.scss';
import classNames from "classnames";
import {useContext, useId, useState} from "react";
import {useForm, SubmitHandler} from "react-hook-form";
import Link from "next/link";
import {Context} from '@/src/library';
import {C_MainButton} from '@/src/components/c_MainButton';

type FormValues = {
    name: string;
    phone: string;
    email: string;
    policyAgree: boolean;
};
declare var Comagic:any, ga:any, ym:any;
export const S_Form = ({
                           header,
                           subheader,
                           button = "Получить предложение",
                           modal = false
                       }: { header: string, subheader?: string, button?: string, modal?: boolean }): JSX.Element => {

    const [isCheckValid, setIsCheckValid] = useState(false);
    const {lead} = useContext(Context);
    const [leadState, setLeadState] = lead;
    const validName = /^((([а-яА-ЯЁё-]){1,30})(\s{1,3}|)){1,5}$|^((([a-zA-Z-'`]){1,30})(\s{1,3}|)){1,5}$/u;

    const {register, handleSubmit, formState: {errors}, reset} = useForm<FormValues>();

    const onSubmit: SubmitHandler<FormValues> = async data => {
        console.log(data);
        setLeadState(true);
        Comagic.addOfflineRequest({
            'name': data.name,
            'phone': data.phone,
            'email': data.email,
            'message': 'Отправка данных'
        }, function (o: any) {
            let response = JSON.parse(o.response);
            if (response.success) {
                setLeadState(true);
                // GTM & Metrika
                try {
                    if (ga) {
                        ga("create", "UA-193151482-1", {name: "tracker"});
                        ga('tracker.send', 'event', 'submit', 'send_button');
                    }
                } catch (error) {
                    console.log(error);
                }

                try {
                    if (ym) {
                        ym(74770351, 'reachGoal', 'send_button');
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                alert('При отправке данных формы произошла ошибка!')
            }
        });
        reset();
    }
    const idCheckbox = useId() + '-checkbox';
    return (
        <div className={classNames(classes.root, {[classes.modalContent]: modal})}>
            <div className={classes.wrapper}>
                <div className={classes.header} dangerouslySetInnerHTML={{__html: header}}/>
                {subheader ? <div className={classes.subheader} dangerouslySetInnerHTML={{__html: subheader}}/> : false}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <input {...register("name", {
                            required: "Обязательное поле",
                            pattern: {
                                value: validName,
                                message: "Неверное имя"
                            }
                        })} type="text" placeholder="Как к вам обращаться*"/>
                        {errors?.name && <p className="error">{errors.name.message}</p>}
                    </div>
                    <div>
                        <InputMask
                            mask={"+7-999-999-99-99"}
                            alwaysShowMask={false}
                            placeholder="Номер телефона*"
                            {...register("phone", {required: "Обязательное поле"})}
                        />
                        {errors?.phone && <p className="error">{errors.phone.message}</p>}
                    </div>
                    <div>
                        <input {...register("email", {
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Неверный e-mail адрес"
                            }
                        })} data-input="email" type="email" placeholder="E-mail"/>
                        {errors?.email && <p className="error">{errors.email.message}</p>}
                    </div>

                    <div className={classes.frmBtnWrapper}>
                        <input id={idCheckbox} type="checkbox" checked={isCheckValid}
                               {...register("policyAgree", {required: true})}
                               onChange={() => {
                                   setIsCheckValid(prev => !prev)
                               }}

                        />
                        <label htmlFor={idCheckbox}
                               className={classNames({[classes.checkboxWrapperDrilling]: errors?.policyAgree})}>
                            <span>Я согласен с обработкой <Link className={classes.policy} href="policy"
                                                                target="_blank">персональных данных</Link>
                            </span>
                        </label>
                        <C_MainButton className={classNames(classes.btnn, {[classes.formBtn]: modal})}
                                      text={modal ? 'Оставить заявку' : 'Получить предолжение'} form={true}/>
                    </div>
                </form>
            </div>
        </div>
    )
}
