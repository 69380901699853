import classNames from 'classnames';
import classes from './style.module.scss';
import Image, { StaticImageData } from 'next/image';
import { useContext } from 'react';
import { Context } from '@/src/library';
import { sizes } from '@/src/data/sizes';

interface SliderElement {
    className?: any,
    title: string,
    description: string,
    color: string,
    image: StaticImageData,
    setIsIndexActiveSlide: Function,
    count: number,
}

export const C_SliderElement = ({className, title, description, image, setIsIndexActiveSlide, count} : SliderElement): JSX.Element => {
  
  const cls = classNames(classes.root, {[className]: className});
  const {size} = useContext(Context)

  const {width} = size;

  let pages = ['Озеленение', 'Ландшафтный дизайн', 'Рестораны и кафе', "Парк и зоны отдыха", "Cпортивная площадка"]

  return (
    <div className={cls}>
        <div className={classes.slider_photo}>
            <Image src={image} width={908} height={641} alt='Фото стандарта'/>
        </div>
        <div className={classes.card_wrapper}>
            <div className={classes.title_wrapper}>
                <div className={classes.title_svg} />
                <h3>{title}</h3>
            </div>
            <div className={classes.card_description}>
               {description}
            </div>
            
            <ul className={classes.pagination}>
                {
                pages.map((item, i ) => {
                    
                    return (
                        <li key={i} className={classNames(classes.pagination_btn, {[classes.pagination_btn__active]: (count === i)})} onClick={(e) => 
                        setIsIndexActiveSlide(i + 1)}>
                            <span>{item}</span>
                        </li>)
                })
                }

            </ul>
            <div className={classes.backSlideBtn} onClick={() => setIsIndexActiveSlide((prev: number) => prev < 2 ? 5 : prev - 1)}>
                {width <= sizes.widthNotebookPlus ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="5.851" height="11.598" viewBox="0 0 5.851 11.598">
                        <path id="Линия_17" data-name="Линия 17" d="M5.469,5.475-.33.375.33-.375,5.469,4.144,10.608-.375l.66.751Z" transform="translate(5.475 0.33) rotate(90)" fill="#bababa"/>
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.474" height="20.519" viewBox="0 0 13.474 20.519">
                        <g id="Сгруппировать_2728" data-name="Сгруппировать 2728" transform="translate(0.424 0)">
                            <path id="Линия_17" data-name="Линия 17" d="M6.312,7.161-.424.424.424-.424,6.312,5.464,12.2-.424l.849.849Z" transform="translate(0 13.358)" fill="#bababa"/>
                            <path id="Линия_122" data-name="Линия 122" d="M.6,19.577H-.6V0H.6Z" transform="translate(6.313 0)" fill="#bababa"/>
                        </g>
                    </svg>
                }
            </div>
            <div className={classes.forwardSlideBtn} onClick={() => setIsIndexActiveSlide((prev: number) => prev > 4 ? 1 : prev + 1)}>
                {width <= sizes.widthNotebookPlus ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="5.851" height="11.598" viewBox="0 0 5.851 11.598">
                        <path id="Линия_17" data-name="Линия 17" d="M10.608,5.185,5.469.666.33,5.185l-.66-.751,5.8-5.1,5.8,5.1Z" transform="translate(5.185 0.33) rotate(90)" fill="#bababa"/>
                    </svg>
                :
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.473" height="20.519" viewBox="0 0 13.473 20.519">
                        <g id="Сгруппировать_2728" data-name="Сгруппировать 2728" transform="translate(0.424 0.849)">
                            <path id="Линия_17" data-name="Линия 17" d="M12.73,7.8,6.842,1.909.954,7.8.106,6.948,6.842.211l6.737,6.737Z" transform="translate(-0.53 -1.06)" fill="#bababa"/>
                            <path id="Линия_122" data-name="Линия 122" d="M.6,19.577H-.6V0H.6Z" transform="translate(6.312 0.094)" fill="#bababa"/>
                        </g>
                    </svg>
                }
            </div>
        </div>
    </div>
  )
}