import classes from './style.module.scss';
import classNames from "classnames";

export const C_SuccessLead = ({
                                  header,
                                  subheader,
                                  modal = false
                              }: { header?: string, subheader?: string, modal?: boolean }): JSX.Element => {

    return (
        <div className={classNames(classes.root, {[classes.modalContent]: modal})}>
                {header ? <div className={classes.header} dangerouslySetInnerHTML={{__html: header}}/> : false}
                {subheader ? <div className={classes.subheader} dangerouslySetInnerHTML={{__html: subheader}}/> : false}
        </div>
    )
}
