import React, { useEffect } from "react";

import classes from './stye.module.scss';
import classNames from "classnames";

import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { S_Plans } from "../s_Plans";

// install Swiper modules

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

export const S_PlansSlider = ({className, items, activeProject} : {className?: any, items: any[], activeProject: number}): JSX.Element => {
    const cls = classNames(classes.root, { [className]: className });

    const [swiper, setSwiper]:any = React.useState()

    useEffect(() => {
        if (swiper) {
            swiper.slideTo(activeProject)
        }
    }, [activeProject])

    return (
        <div className={cls}>
            <Swiper
                pagination={{
                    el: '.swiper-pagination',
		            'clickable': true
                }}
                preventClicks={true}
                speed={0}
                slidesPerView={1}
                loopAdditionalSlides={3}
                spaceBetween={0}
                loop={true}
                className={classNames(classes.swiper)}
                initialSlide={0}
                allowTouchMove={false}
                onInit={(evt) => {
                    setSwiper(evt)
                }}
                
            >   
            <h2>Планировочные решения</h2>
                {items.map((item, i) => {
                    return (
                        <SwiperSlide className = {classNames(classes.swiperSlide)} key={i}>
                            <S_Plans plans={item}/>
                        </SwiperSlide>    
                    )
                })}
            </Swiper>
        </div>
    )
}