import classes from './style.module.scss';
import classNames from "classnames";
import {useState} from "react";

type MyType = {
    data: string;
    link: string;
}


const diaryData: MyType[] = [
    {
        data: 'Март 2023',
        link: 'https://player.vimeo.com/video/812053001?h=2181f97327%22frameborder=%220%22'
    },
    {
        data: 'Февраль 2023',
        link: 'https://player.vimeo.com/video/801198145?h=afca9bc05e'
    },
    {
        data: 'Январь 2023',
        link: 'https://player.vimeo.com/video/791143927?h=afca9bc05e'
    }
];

export const S_Diary = ({className}: {className?: any}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });
    const [selected, setSelected] = useState(0)

    return (
        <div className={cls} id={'diary'}>
            <div className={classes.container}>
                    <h3 className={classes.title}>
                        Дневник строительства
                    </h3>
                    <div className={classes.calendar}>
                        <button
                            className={classes.leftBtn}
                            onClick={() => {setSelected(prev => {
                            if (prev !== diaryData.length - 1) {
                                return prev + 1;
                            }
                            return prev;
                        })}} />
                        <ul>
                            {diaryData.map((el: MyType, i: number) => {
                                return (
                                    <li
                                        key={el.data}
                                        className={classNames(classes.calendar_element, {[classes.active]: i === selected})}
                                        onClick={() => {setSelected(i)}}
                                    >
                                        {el.data}
                                    </li>
                                )
                            })}
                        </ul>
                        <button
                            className={classes.rightBtn}
                            onClick={() => {setSelected(prev => {
                            if (prev !== 0) {
                                return prev - 1;
                            }
                            return prev;
                        })}} />
                    </div>
                    <div className="content">
                        <div
                             className={classNames(classes.content_element, classes.active)}
                             style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                            <iframe
                                className={classes.iframe}
                                src={diaryData[selected].link}
                                frameBorder="0"
                                allow="autoplay; fullscreen"
                                allowFullScreen
                            />
                        </div>
                    </div>
                </div>
        </div>
    );
}