import classes from './style.module.scss';
import classNames from "classnames";
import Image from "next/image";

export const C_Popup = ({className, territoryData, selectedSlide, isPopup, setIsPopup}: {className?: any, territoryData: any, selectedSlide: number, isPopup: boolean, setIsPopup:(isPopup:boolean)=>void}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className, [classes.showPopup]: isPopup });

    return (
        <div
            className={cls}
        >
            <div className={classes.wrapImg}>
                <button
                    className={classes.closeBtn}
                    onClick={() => setIsPopup(false)}
                />
                <Image
                    className={classes.image}
                    src={territoryData[selectedSlide - 1].image}
                    alt={territoryData[selectedSlide - 1].title}
                    width={320}
                    height={214}
                />
            </div>
        </div>
    );
}