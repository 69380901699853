import classes from './style.module.scss';
import classNames from "classnames";
import { useState } from 'react';
import { C_Slider } from '@/src/sections/s_StoneStandarts/c_Slider';
import { C_SliderElement } from '../s_StoneStandarts/c_SliderElement/index'
import { sliderData } from '@/src/data/mocks';

export const S_StoneStandarts = ({className} : {className?: any}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });

    const [isIndexActiveSlide, setIsIndexActiveSlide] = useState<number>(1)

    let allCards : any[] =[];

      sliderData.forEach((item, i) => {
        allCards.push(<C_SliderElement key={i} count={i} description={item.description} color={item.color} image={item.image} title={item.title} setIsIndexActiveSlide={setIsIndexActiveSlide}/>)
      })
      
    
    return (
      <div className={classes.wrapRoot}>
        <div className={cls}>
          <h2>Стандарты Stone</h2>
            <div className={classes.color_line}></div>
                <C_Slider className={classes.slider} items={allCards} isIndexActiveSlide={isIndexActiveSlide}/>
        </div>
      </div>
  )
}