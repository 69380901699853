import classes from './style.module.scss';
import classNames from "classnames";
import Image, { StaticImageData } from 'next/image';
import {Context} from '../../library';
import {useContext} from "react";
import { C_MainButton } from '@/src/components/c_MainButton';
import { heroData } from '@/src/data/mocks';

interface HeroData {
    image: StaticImageData,
    description: string[],
    header: string,
    sale: string
}

export const S_Hero = ({className}: {className?: any}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });
    const {popup} = useContext(Context);
    const [popupState, setPopupState]=popup;

    const  {image, description, header, sale}: HeroData = heroData;

    return (
        <div className={cls}>
            <div className={classes.wrapper}>
                <div className={classes.projectLogo} />
                <div className={classes.imageWrapper}>
                    <div className={classes.nextImage}>
                        <Image src={image} width={948} height={622} alt="Фото проекта"/>
                    </div>
                    <p>{sale}</p>
                    <p className={classes.vertical}>Рост цены до 40%</p>
                </div>
                <div className={classes.description}>
                    <h3>{header}</h3>
                    <ul>
                        {description.map((item, i) => 
                            <li key={i}>{item}</li>
                        )}
                    </ul>
                </div>
                <div className={classes.award}>
                    <h3>ОФИСНЫЙ ДЕВЕЛОПЕР №1</h3>
                    <span>по рейтингу РБК</span>
                    <p>2023</p>
                    <a href="https://realty.rbc.ru/news/63ff8f659a7947076f71c138" target={'_blank'}>Читать на РБК</a>
                </div>
                <div className={classes.btnWrapper}>
                    <C_MainButton className={classes.heroBtn} text="Получить предложение" onClick={() => setPopupState((o: any) => !o)}/>
                </div>
            </div>
            <div className={classes.imageWrapper_desktop}>
                <div className={classes.nextImage}>
                    <Image src={image} width={948} height={622} alt="Фото проекта"/>
                </div>
                <p>{sale}</p>
                <p className={classes.vertical}>Рост цены до 40%</p>
            </div>
        </div>
    );
}
