import classes from './style.module.scss';
import classNames from "classnames";
import {C_Slider} from "./c_Slider";
import {C_Pagination} from "./c_Pagination";
import {useState} from "react";
import type { Swiper } from 'swiper';

export const C_Blocks = ({className, data, selectedSlide, setSelectedSlide} : {className?: any, selectedSlide: number, data: any, setSelectedSlide: any}) => {
    const cls = classNames(classes.root, {[className]: className });
    const [swiperInstance, setSwiperInstance] = useState<Swiper | null | any>(null);
    const slideTo = (index: number) => {
        if (swiperInstance !== null) {
            swiperInstance.slideTo(index - 1, 0);
        }
    };

    return (
        <div className={cls}>
            <div className={classes.constantText}>Преимущества STONE{`\u00A0`}Савеловская</div>
            <div className={classes.contentTitle} dangerouslySetInnerHTML={{ __html: data[selectedSlide - 1].title}} />
            <C_Slider
                className={classes.C_Slider}
                data={data}
                setSelectedSlide={setSelectedSlide}
                setSwiperInstance={setSwiperInstance}
                selectedSlide={selectedSlide}
                slideTo={slideTo}
            />
            <C_Pagination
                className={classes.C_Pagination}
                data={data}
                selectedSlide={selectedSlide}
                slideTo={slideTo}
            />
            <div className={classes.contentText} dangerouslySetInnerHTML={{ __html: data[selectedSlide - 1].content}} />
        </div>
    );
}