import classes from './style.module.scss';
import classNames from "classnames";
import { useContext} from 'react';
import { Context } from '@/src/library';
import { sizes } from '@/src/data/sizes';
import { towers } from '@/src/data/mocks';
import { C_InvestmentCard } from './c_InvestmentCard';
import { C_InvestmentSlider } from '@/src/sections/S_ProfitableInvestment/c_InvestmentSlider';
import { C_InvestmentList } from '@/src/sections/S_ProfitableInvestment/c_InvestmentList';

export const S_ProfitableInvestment = ({className, setActiveProject} : {className?: any, setActiveProject: Function}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });

    const {size} = useContext(Context);
    const {width} = size;     
    
    let allCards : any[] = [];

      towers.forEach((tower, i) => {
        allCards.push(<C_InvestmentCard key={i} item={tower}/>)
      })
      

    return (
      <div className={classes.wrapRoot} id={'offices'}>
        <div className={cls}>
            <h2>Выгодные инвестиции</h2>
            {width < sizes.widthNotebookPlus ?
                <C_InvestmentSlider setActiveProject={setActiveProject} items={allCards} />
            :
                <C_InvestmentList setActiveProject={setActiveProject} items={towers}/>
            }   
        </div>
      </div>
    )
}