import classes from './style.module.scss';
import classNames from "classnames";
import {C_TerritoryBlocks} from "./c_TerritoryBlocks";
import {C_Popup} from "./c_Popup";
import map from "./img/map.jpg";
import sitPlan from "./img/sitPlan.jpg";
import office from "./img/office.jpg";
import {useState} from "react";


type MyType = {
    image: any;
    title: string;
    content: string;
}

export const territoryData: MyType[] = [
    {
        image: map,
        title: 'Локация',
        content: 'Концепция BE CITIZEN определяется в первую очередь локацией проекта. Близость метро, удобный выезд на ТТК (500 м) дает возможность его резидентам удобно добираться до работы и быстро передвигаться по центру города. Идеально для тех, кто привык быть в центре деловой активности и городской жизни.',
    },
    {
        image: sitPlan,
        title: 'Ситуационный план',
        content: 'На территории предусмотрено несколько функциональных зон, связанных в единое общественное пространство. На стилобате будут организованы места для работы на свежем воздухе, а основное пространство будет отдано под парк.',
    },
    {
        image: office,
        title: 'Офис продаж',
        content: 'Дом STONE — пространство, объединяющее все офисные проекты класса А by STONE HEDGE. Он призван ближе познакомить клиентов и брокеров с философией и подходом девелопера к проектированию и строительству. На площади около 300 м² разместились шоу-рум офисных проектов класса А на первом этаже и четыре зоны для переговоров и отдыха гостей на втором.',
    },
]

export const S_Territory = ({className} : {className?: any}) => {
    const cls = classNames(classes.root, {[className]: className });
    const [selectedSlide, setSelectedSlide] = useState(1);
    const [isPopup, setIsPopup] = useState(false);

    return (
        <div className={cls}>
            <C_TerritoryBlocks
                className={classes.C_TerritoryBlocks}
                territoryData={territoryData}
                selectedSlide={selectedSlide}
                setSelectedSlide={setSelectedSlide}
                setIsPopup={setIsPopup}
            />
            <div className={classes.vertical}>
                Белорусский деловой район
            </div>
            <C_Popup
                territoryData={territoryData}
                selectedSlide={selectedSlide}
                isPopup={isPopup}
                setIsPopup={setIsPopup}
            />
        </div>
    );
}