import classes from './style.module.scss';
import classNames from "classnames";
import {C_Blocks} from "./c_Blocks";
import picture1 from "./img/architectory.jpg";
import picture2 from "./img/plans.jpg";
import picture3 from "./img/system.jpg";
import picture4 from "./img/lobby.jpg";
import picture5 from "./img/infrasctructure.jpg";
import picture6 from "./img/parks.jpg";
import picture7 from "./img/parking.jpg";

import {useState} from "react";


type MyType = {
    romeNumber: string;
    image: any;
    title: string;
    content: string;
}

export const data: MyType[] = [
    {
        romeNumber: 'I.',
        image: picture1,
        title: `Архитектура от${'\u00A0'}«Цимайло Ляшенко и${'\u00A0'}Партнеры» `,
        content: `Архитекторы STONE Савеловская — бюро «Цимайло Ляшенко и${'\u00A0'}Партнеры» — спроектировали бизнес-центр в${'\u00A0'}соответствии с${'\u00A0'}принципами архитектуры «вне времени». Две <nobr>100-метровые</nobr> офисные башни выделяются на${'\u00A0'}фоне городской панорамы, а${'\u00A0'}продуманная архитектура, благоустроенная территория и${'\u00A0'}удобная инфраструктура будут притягивать жителей всего района. Отдельный акцент в${'\u00A0'}бизнес-центре делается на увеличенный уровень остекления, пропускающий оптимальное количество дневного света и${'\u00A0'}позволяющий наслаждаться видами на город.`,
    },
    {
        romeNumber: 'II.',
        image: picture2,
        title: 'Функциональные планировки ',
        content: `Планировки офисных этажей соответствуют требованиям функционального офиса: глубина помещений <nobr>8-10 м</nobr>, высота потолков — 3,65${'\u00A0'}м, шаг колонн <nobr>8,4х8,4.</nobr> Окна в${'\u00A0'}пол обеспечат оптимальный уровень инсоляции и${'\u00A0'}предусматривают возможность естественного проветривания.`,
    },
    {
        romeNumber: 'III.',
        image: picture3,
        title: 'Современные инженерные системы',
        content: `Офисные башни STONE Савеловская будут оснащены всеми современными инженерными системами: центральное кондиционирование, приточно-вытяжная вентиляция с увеличенным объемом воздуха и${'\u00A0'}многоуровневой системой очистки. Дополнительно будет предусмотрена возможность естественного проветривания.`,
    },
    {
        romeNumber: 'IV.',
        image: picture4,
        title: `Лобби от лондонской <nobr>дизайн-студии</nobr>`,
        content: `Интерьер лобби и общественных пространств в${'\u00A0'}нейтральных тонах для STONE Савеловская создала лондонская студия KEMELIN PARTNERS. Дизайн-проект отвечает современным европейским трендам: интерьер базируется на естественном свете, фактурном бетоне и${'\u00A0'}элементах биофильного дизайна, которые призваны поддерживать психологический комфорт резидентов и${'\u00A0'}гостей. Продуманное зонирование и${'\u00A0'}система навигации позволят грамотно распределить трафик внутри офисных башен и${'\u00A0'}создать дополнительные функциональные пространства для коммуникаций и${'\u00A0'}отдыха резидентов в${'\u00A0'}лобби и${'\u00A0'}торговой галерее.`,
    },
    {
        romeNumber: 'V.',
        image: picture5,
        title: 'Инфраструктура',
        content: `В двухуровневом стилобате STONE Савеловская разместится вся необходимая инфраструктура для оптимального функционирования бизнес-центра и${'\u00A0'}удовлетворения потребностей резидентов, в${'\u00A0'}том числе: торговые помещения, кафе и${'\u00A0'}рестораны с${'\u00A0'}летними верандами, столовая для сотрудников, аптеки, банкоматы, современный фитнес-центр. Бизнес-центр расположен в${'\u00A0'}окружении развивающейся жилой застройки, что обеспечит дополнительный клиентский поток к${'\u00A0'}инфраструктуре STONE Савеловская, сделав ее точкой притяжения для жителей района.`,
    },
    {
        romeNumber: 'VI.',
        image: picture6,
        title: `Благоустройство от${'\u00A0'}бюро Basis`,
        content: `Концепцию благоустройства для STONE Савеловская девелопер разработает совместно с${'\u00A0'}ведущим архитектурным бюро Basis. Учитывая активный пешеходный трафик на территории бизнес-центра общее благоустройство включает две зоны: общественное пространство на уровне земли со свободным доступом и${'\u00A0'}закрытая территория для резидентов с${'\u00A0'}собственным кафе и${'\u00A0'}рабочими зонами на открытом воздухе, оборудованными зарядными устройствами для гаджетов. Одной из составляющих благоустройства станет небольшая воркаут-зона. На главной площади бизнес-центра архитекторы предусмотрели зеленые островки и${'\u00A0'}ландшафтные холмы, арт-фонтаны и${'\u00A0'}скамейки для отдыха.`,
    },
    {
        romeNumber: 'VII.',
        image: picture7,
        title: 'Паркинг',
        content: `В STONE Савеловская предусмотрен подземный и${'\u00A0'}наземный многоуровневый паркинг для резидентов, а также гостевой паркинг с${'\u00A0'}выделенной зоной для автомобилей каршеринга. В${'\u00A0'}паркингах будет осуществляться круглосуточная система видеонаблюдения, а${'\u00A0'}контроль доступа будет обеспечиваться по${'\u00A0'}считыванию госномера, QR-коду и${'\u00A0'}телефону. Дополнительно бизнес-центр будет оснащен объектами инфраструктуры микромобильности и${'\u00A0'}зарядными устройствами для электромобилей.`,
    },
]

export const S_Advantages = ({className} : {className?: any}) => {
    const cls = classNames(classes.root, {[className]: className });
    const [selectedSlide, setSelectedSlide] = useState(1);

    return (
        <div className={cls}>
            <C_Blocks
                className={classes.C_Blocks}
                data={data}
                selectedSlide={selectedSlide}
                setSelectedSlide={setSelectedSlide}
            />
        </div>
    );
}