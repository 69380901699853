import Head from 'next/head'
import {S_HealthyBuilding} from '@/src/sections/s_HealthyBuilding';
import {S_StoneStandarts} from '@/src/sections/s_StoneStandarts';
import {S_ProfitableInvestment} from '@/src/sections/S_ProfitableInvestment';
import {S_Hero} from '@/src/sections/s_Hero_section';
import {S_Form} from "@/src/sections/s_Form";
import {S_ModalForm} from "@/src/sections/s_ModalLead";
import {C_SuccessLead} from "@/src/components/с_SuccessLead";
import { S_Investment } from '@/src/sections/s_Investment';
import { S_PlansSlider } from '@/src/sections/S_PlansSlider';
import { allPlans } from '@/src/data/mocks';
import React, {useContext, useState } from 'react';
import { S_Diary } from '@/src/sections/s_Diary';
import { S_Territory } from '@/src/sections/s_Territory';
import {Context} from '@/src/library';
import { S_Advantages } from '@/src/sections/s_Advantages';


export default function Home():JSX.Element {
    const [ activeProject, setActiveProject] = useState<number>(0);
    const {lead} = useContext(Context);
    const [leadState, setLeadState] = lead;

    return (
    <>
      <Head>
        <title>Stone Савёловская</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main>
          <S_Hero />
          <S_ProfitableInvestment setActiveProject={setActiveProject}/>
          <S_PlansSlider activeProject={activeProject} items={allPlans}/>
          <S_Territory />
          <S_Diary />
          <S_Advantages />
          {
              !leadState ?
                  <S_Form header="Первый взнос 1,6 млн руб. <br/> рассрочка 0%"
                          subheader="Оставьте заявку <br/> и получите консультацию"/> :
                  <C_SuccessLead header="Отлично!" subheader="Наш менеджер свяжется с Вами в ближайшее время"
                                 modal={true}/>
          }
          <S_Investment />
          <S_StoneStandarts />
          <S_HealthyBuilding />
          <S_ModalForm/>
      </main>
    </>
  )
}
