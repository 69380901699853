import classes from './style.module.scss';
import classNames from "classnames";
import {C_Slider_plans} from "./c_Slider_plans";
import {C_Situation_Plan} from "./c_Situation_Plan";
import {C_Pagination} from "./c_Pagination";
import {C_Floors} from "./c_Floors";
import {useContext, useState} from "react";
import type { Swiper } from 'swiper';
import { C_MainButton } from '@/src/components/c_MainButton';
import {Context} from "@/src/library";

interface Plan {
    name: string,
    square: string,
    plans: any[],
    implement: any[],
    situationPlan: string
}

export const C_Tower_plans = ({className, plans, selectedSlide, setSelectedSlide, setIsPopupSituation, setIsPopupFloor} : {className?: any, selectedSlide: number, plans: Plan, setSelectedSlide: any, setIsPopupSituation: any, setIsPopupFloor: any}) => {
    const cls = classNames(classes.root, {[className]: className });
    const [swiperInstance, setSwiperInstance] = useState<Swiper | null | any>(null);
    const slideTo = (index: number) => {
        if (swiperInstance !== null) {
            swiperInstance.slideTo(index - 1, 0);
        }
    };
    const {menu, popup} = useContext(Context);
    const [popupState, setPopupState] = popup;
    
    return (
        <div className={cls}>
            <div className={classes.title}>{plans.name}</div>
            <div className={classes.blockType}>Планировка этажа</div>
            <div className={classes.commonSquare}>{plans.square}<sup>{plans.square === '' ? null : 2}</sup></div>
            <C_Slider_plans
                className={classes.C_Slider_plans}
                plans={plans}
                setSelectedSlide={setSelectedSlide}
                setSwiperInstance={setSwiperInstance}
                setIsPopupFloor={setIsPopupFloor}
            />
            <C_Situation_Plan
                className={classes.C_Situation_Plan}
                image={plans.situationPlan}
                setIsPopupSituation={setIsPopupSituation}
            />
            <C_Pagination
                className={classes.C_Pagination}
                plans={plans}
                selectedSlide={selectedSlide}
                slideTo={slideTo}
            />
            <C_Floors
                className={classes.C_Floors}
                selectedSlide={selectedSlide}
                plans={plans}
            />
            <div className={classes.wrapSquareInfo}>
                <div className={classes.squareInfo}>
                    <div className={classes.realise}>Форма реализации</div>
                    <div className={classes.saleType}>Продажа</div>
                </div>
                <ul className={classes.squareInfoList}>
                    <li className={classes.squareInfoItem}>Офисы {plans.implement[0]}<sup>2</sup></li>
                    <li className={classNames(classes.squareInfoItem,{[classes.squareInfoItem_hidden]: plans.implement.length === 1})}>Офисные этажи — {plans.implement[1]}<sup>2</sup></li>
                </ul>
            </div>
            <div className={classes.wrapExtraInfo}>
                <div className={classes.extraInfo}>Информация <br className={classes.onlyDesktop}/>о планировке</div>
                <div className={classes.extraInfoText}>Мокрые точки</div>
            </div>
            <div className={classes.wrapPriceInfo}>
                <div className={classes.priceInfo}>Продажа офисов <br className={classes.onlyDesktop}/>и видовых этажей</div>
                <div className={classes.priceInfoText}>от 300 000 руб./м<sup>2</sup></div>
            </div>
             <C_MainButton text={'Узнать стоимость'} className={classes.btn_cost} onClick={() => setPopupState((o: any) => !o)} />
        </div>
    );
}
