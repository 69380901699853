import { useState } from 'react';
import { C_InvestmentCard } from '../c_InvestmentCard';
import classes from './style.module.scss';
import classNames from "classnames";

export const C_InvestmentList = ({className, items, setActiveProject} : {className?: any, items: any[], setActiveProject: Function}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });

    const [activeSlide, setActiveSlide] = useState<number>(0)
    
    return (
      
        <div className={cls}>
            <ul>
                {items.map((item, i) => 
                    <li key={i} onClick={() => setActiveProject(i)}>
                        <C_InvestmentCard count={i} item={item} activeSlide={activeSlide} setActiveSlide={setActiveSlide}/>
                    </li>
                )}
            </ul>
      </div>
  )
}