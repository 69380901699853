import classes from './style.module.scss';
import classNames from "classnames";
import {C_Tower_plans} from "./c_Tower_d_plans";
import {C_PopupSituationPlan} from "./c_PopupSituationPlan";
import {useState} from "react";

interface Plan {
    name: string,
    square: string,
    plans: any[],
    implement: any[],
    situationPlan: string
}

export const S_Plans = ({className, plans} : {className?: any, plans: Plan}) => {
    const cls = classNames(classes.root, {[className]: className });
    const [selectedSlide, setSelectedSlide] = useState(1);
    const [isPopupFloor, setIsPopupFloor] = useState(false);
    const [isPopupSituation, setIsPopupSituation] = useState(false);

    return (
        <div className={cls}>
            <C_Tower_plans
                className={classes.C_Tower_d_plans}
                plans={plans}
                selectedSlide={selectedSlide}
                setSelectedSlide={setSelectedSlide}
                setIsPopupSituation={setIsPopupSituation}
                setIsPopupFloor={setIsPopupFloor}
                
            />
            <C_PopupSituationPlan
                image={plans.situationPlan}
                isPopupSituation={isPopupSituation}
                setIsPopupSituation={setIsPopupSituation}
            />
        </div>
    );
}