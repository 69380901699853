import React, { useContext, useEffect} from "react";

import classes from './style.module.scss';
import classNames from "classnames";

import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from "react";
import { Context } from "@/src/library";
import { sizes } from "@/src/data/sizes";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

export const C_Slider = ({className, items, isIndexActiveSlide} : {className?: any, items: any[], isIndexActiveSlide: number}): JSX.Element => {
    const cls = classNames(classes.root, { [className]: className });

    const [slide, setSlide] = useState<number>(0);
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [activeSlide, setActiveSlide] = useState<number>(0);
    const [swiper, setSwiper]:any = React.useState()
    const {size} = useContext(Context)
    const {width} = size

    // ПРолистывает к слайду , если нажата строчка с заголовком

    useEffect(() => {
        if (swiper) {
            swiper.slideTo(isIndexActiveSlide)
        }
    }, [isIndexActiveSlide])
    
    return (
        <div className={cls}>
            {<div className={classes.swiper_button_prev} onClick={() => (swiper.slidePrev(), slide > 0 ? setSlide(slide - 1) : setSlide(slide))}
            />} 
            <Swiper
                navigation={{
                    prevEl: '.swiper_button_prev',
                    nextEl: '.swiper_button_next',
                }}
                onSlideChangeTransitionEnd={() => setIsEnd(true)}
                speed={0}
                slidesPerView={1}
                spaceBetween={width < sizes.widthNotebookPlus ? -58 : 0}
                loop={true}
                className={classNames(classes.swiper)}
                initialSlide={0}
                onInit={(evt) => {
                    setSwiper(evt)
                }}
                onSlideChange={(swiper) => (setActiveSlide(swiper.realIndex), setIsEnd(false))}
            >   
                {items.map((item, i) => {
                    return (
                        <SwiperSlide className = {classNames(classes.swiperSlide,{[classes.swiperSlideActive]: activeSlide === i && isEnd})} key={i}>
                            {item}
                        </SwiperSlide>    
                    )
                })}
            </Swiper>
            
            {<div className={classes.swiper_button_next} onClick={() => (swiper.slideNext(), slide < items.length -1 ? setSlide(slide + 1) : setSlide(slide))}
            />}
        </div>
    )
}