import classes from './style.module.scss';
import classNames from "classnames";
import Image from 'next/image';
import SwiperCore, { EffectFade, Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState} from "react";
import Popup from 'reactjs-popup';

// install Swiper modules

SwiperCore.use([EffectFade, Navigation, Pagination, A11y, Autoplay]);

export const C_Slider_plans = ({className, plans, setSelectedSlide, setSwiperInstance, setIsPopupFloor} : {className?: any, plans: any, setSelectedSlide: any, setSwiperInstance: any, setIsPopupFloor:(isPopupFloor:boolean)=>void}) => {
    const cls = classNames(classes.root, {[className]: className });
    const ref:any = useRef(null);
  
    const [popupState, setPopupState] = useState<boolean>();
    const closeModal = () => setPopupState(false);

    useEffect(() => {
        setSelectedSlide(1)
    },[])

    return (
        <div className={cls}>
            <Swiper
                className={classes.swiper}
                spaceBetween={5}
                onSwiper={setSwiperInstance}
                onSlideChange={
                    (data) => {
                        setSelectedSlide(data.activeIndex + 1);
                        
                    }
                }
                navigation
                style={plans.name === "Башня 1" ? {maxWidth: 819 + 'px'} : plans.name === "Башня 2" ? {maxWidth: 929 + 'px'} :  {maxWidth: 916 + 'px'}}
            >

                {plans.plans.map((el: any, i: number) => {
                    return (
                        <SwiperSlide
                            key={i}
                            className={classes.SwiperSlide}
                            style={plans.name === "Башня 1" ? {maxWidth: 819 + 'px'} : plans.name === "Башня 2" ? {maxWidth: 929 + 'px'} :  {maxWidth: 916 + 'px'}}
                        >                  
                            <Popup open={popupState} ref={ref} trigger={
                                <Image
                                className={classNames(classes.Image)}
                                src={el.plan}
                                alt={`plan ${i + 1}`}
                                width={plans.name === "Башня 1" ? 819 : plans.name === "Башня 2" ? 929 : 916}
                                height={462}
                                style={plans.name === "Башня 1" ? {maxWidth: 819 + 'px'} : plans.name === "Башня 2" ? {maxWidth: 929 + 'px'} :  {maxWidth: 916 + 'px'}}
                                onClick={() => setPopupState((o: any) => !o)}
                            /> 
                            } onClose={closeModal}
                            lockScroll={true}
                            key={`tp-${i + 1}`} modal>
                                        <div
                                            className={cls}
                                        >
                                            <div className={classes.wrapImg}>
                                                
                                                <button
                                                    className={classes.closeBtn}
                                                    onClick={() => ref.current ? ref.current.close(): null}  
                                                />
                                                <Image
                                                    className={classes.imagePopup}
                                                    src={el.plan}
                                                    alt={`plan ${i + 1}`}
                                                    width={plans.name === "Башня 1" ? 819 : plans.name === "Башня 2" ? 929 : 916}
                                                    height={462}
                                                    style={plans.name === "Башня 1" ? {maxWidth: 819 + 'px'} : plans.name === "Башня 2" ? {maxWidth: 929 + 'px'} :  {maxWidth: 916 + 'px'}}                                             
                                                />
                                            </div>
                                        </div>
                            </Popup>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    );
}
