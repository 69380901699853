import { sizes } from '@/src/data/sizes';
import classes from './style.module.scss';
import classNames from "classnames";
import Image, { StaticImageData } from 'next/image';
import { Context } from '@/src/library';
import { useContext } from 'react';

interface InvestmentCard {
    name: string,
    label: string,
    description: string,
    src: StaticImageData,
    properties: {
        station_1: string,
        station_2: string,
        color_1: string,
        color_2: string,
        time_1: string,
        time_2: string,
        square: string,
        ready: string,
    },

}

export const C_InvestmentCard = ({className, item, count, activeSlide, setActiveSlide} : {className?: any, item: InvestmentCard, count?: number, activeSlide?: number, setActiveSlide?: Function }): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });

    const {name, label, description, src, properties} = item;
    const {size}  = useContext(Context);
    const {width} = size;

    return (
        <div className={cls} onClick={(e) => width > sizes.widthNotebookPlus && setActiveSlide ? setActiveSlide(count): null}>
            <h3>
                {name}
                <span className={classNames(classes.underline,{[classes.pressedCardLine]: activeSlide === count })}></span>
            </h3>
            <span className={classes.label}>{label}</span>
            <p>{description}</p>
            <Image width={507} height={336} src={src} alt="Фото башни"/>
            <div className={classes.properties}>
                {properties ?
                    <ul>    
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.207" viewBox="0 0 11.207 11.207" transform="translate(0, 1)">
                                <g id="Сгруппировать_4688" data-name="Сгруппировать 4688" transform="translate(-20 -917)">
                                    <circle id="Эллипс_794" data-name="Эллипс 794" cx="5.604" cy="5.604" r="5.604" transform="translate(20 917)" fill={`${properties.color_1}`}/>
                                    <ellipse id="Эллипс_795" data-name="Эллипс 795" cx="3.1" cy="3.053" rx="3.1" ry="3.053" transform="translate(22.503 919.551)" fill="#fff"/>
                                </g>
                            </svg>
                            {properties.station_1}
                        </li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.207" viewBox="0 0 11.207 11.207">
                                <g id="Сгруппировать_4688" data-name="Сгруппировать 4688" transform="translate(-20 -917)">
                                    <circle id="Эллипс_794" data-name="Эллипс 794" cx="5.604" cy="5.604" r="5.604" transform="translate(20 917)" fill={`${properties.color_2}`}/>
                                    <ellipse id="Эллипс_795" data-name="Эллипс 795" cx="3.1" cy="3.053" rx="3.1" ry="3.053" transform="translate(22.503 919.551)" fill="#fff"/>
                                </g>
                            </svg>
                            {properties.station_2}
                        </li>
                        <li>{properties.time_1}</li>
                        <li>{properties.time_2}</li>
                        <li>{properties.square}<sup style={{lineHeight: 8 + 'px'}}>2</sup></li>
                        <li>{properties.ready}</li>
                    </ul>    
                :
                    null
                }    
            </div>
      </div>
  )
}