import classes from './style.module.scss';
import classNames from "classnames";
import Image from "next/image";

export const C_PopupSituationPlan = ({className, isPopupSituation, setIsPopupSituation, image}: {className?: any, isPopupSituation: boolean, setIsPopupSituation: (isPopupSituation:boolean)=>void, image: string}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className, [classes.showPopup]: isPopupSituation });

    return (
        <div
            className={cls}
        >

            <div className={classes.wrapImg}>
                <button
                    className={classes.closeBtn}
                    onClick={() => setIsPopupSituation(false)}
                />
                <Image
                    className={classes.image}
                    src={image}
                    alt={'Планировка'}
                    width={294}
                    height={203}
                />
            </div>
        </div>
    );
}