import classes from './style.module.scss';
import classNames from "classnames";
import Image from 'next/image';
import SwiperCore, { EffectFade, Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import {useEffect, useContext} from "react";
import { sizes } from '@/src/data/sizes';
import { Context } from '@/src/library';

// import type { Swiper } from 'swiper';

// install Swiper modules
SwiperCore.use([EffectFade, Navigation, Pagination, A11y, Autoplay]);

export const C_Slider = ({className, data, setSelectedSlide, setSwiperInstance, selectedSlide, slideTo} : {className?: any, data: any, setSelectedSlide: any, setSwiperInstance: any, selectedSlide: number, slideTo: any}) => {
    const cls = classNames(classes.root, {[className]: className });

    const {size} = useContext(Context);
    const {width} = size;

    useEffect(() => {
        setSelectedSlide(1)
    },[])

    return (
        <div className={cls}>
            <Swiper
                allowTouchMove={width > sizes.widthNotebookPlus ? false : true}
                className={classes.swiper}
                spaceBetween={5}
                onSwiper={setSwiperInstance}
                onSlideChange={
                    (data) => {
                        setSelectedSlide(data.activeIndex + 1);
                    }
                }
                navigation
            >

                {data.map((el: any, i: number) => {
                    return (
                        <SwiperSlide
                            key={i}
                            className={classes.SwiperSlide}
                        >
                            <Image
                                className={classes.Image}
                                src={el.image}
                                alt={`plan ${i + 1}`}
                                width={1037}
                                height={742}
                            />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <button
                className={classNames(classes.toLeft)}
                onClick={() =>  selectedSlide === 1 ? slideTo(7) : slideTo(selectedSlide -1)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="48.621" height="21.281" viewBox="0 0 48.621 21.281">
                    <g id="Слой_7" data-name="Слой 7" transform="translate(48.621 0.53) rotate(90)">
                        <g id="Сгруппировать_2728" data-name="Сгруппировать 2728" transform="translate(0 0)">
                            <path id="Линия_17" data-name="Линия 17" d="M20.22,0,10.11,10.11,0,0" transform="translate(0 37.45)" fill="none" stroke="#bababa" stroke-width="1.5"/>
                            <line id="Линия_122" data-name="Линия 122" y1="47.41" transform="translate(10.11 0)" fill="none" stroke="#bababa" stroke-width="1.5"/>
                        </g>
                    </g>
                </svg>
            </button>
            <button
                className={classNames(classes.toRight, {[classes.toRightEnd]: selectedSlide === data.length })}
                onClick={() => selectedSlide === 7 ? slideTo(1) : slideTo(selectedSlide + 1)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="48.621" height="21.281" viewBox="0 0 48.621 21.281">
                    <g id="Слой_7" data-name="Слой 7" transform="translate(48.62 0) rotate(90)">
                        <g id="Сгруппировать_2728" data-name="Сгруппировать 2728">
                            <path id="Линия_17" data-name="Линия 17" d="M20.75,11.17,10.64,1.06.53,11.17" fill="none" stroke="#bababa" stroke-width="1.5"/>
                            <line id="Линия_122" data-name="Линия 122" y2="47.41" transform="translate(10.64 1.21)" fill="none" stroke="#bababa" stroke-width="1.5"/>
                        </g>
                    </g>
                </svg>
            </button>
        </div>
    );
}